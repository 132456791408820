// Generated by Framer (d351ec1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["SbIrlWYIp", "gzckTe59u", "GSiUx9rZ2", "iOAlCaNzr", "ibPj7DX6I", "szOBHJWto"];

const serializationHash = "framer-Y9Ho0"

const variantClassNames = {GSiUx9rZ2: "framer-v-1wgnxed", gzckTe59u: "framer-v-ks2ohm", ibPj7DX6I: "framer-v-oca03e", iOAlCaNzr: "framer-v-1or0zkj", SbIrlWYIp: "framer-v-1n00dmi", szOBHJWto: "framer-v-7qsfcq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"2XS - 16x16": "szOBHJWto", "L - 64x64": "gzckTe59u", "M - 40x40": "GSiUx9rZ2", "S - 32x32": "iOAlCaNzr", "XL - 80x80": "SbIrlWYIp", "XS - 24x24": "ibPj7DX6I"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, EhBSjETo1: image ?? props.EhBSjETo1 ?? {src: "https://framerusercontent.com/images/LDZoIENyXzBGgbFwxpOPuM1WFdY.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "SbIrlWYIp"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, EhBSjETo1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "SbIrlWYIp", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(80px, 100vw)", ...toResponsiveImage(EhBSjETo1)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1n00dmi", className, classNames)} data-framer-name={"XL - 80x80"} layoutDependency={layoutDependency} layoutId={"SbIrlWYIp"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({GSiUx9rZ2: {"data-framer-name": "M - 40x40", background: {alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(40px, 100vw)", ...toResponsiveImage(EhBSjETo1)}}, gzckTe59u: {"data-framer-name": "L - 64x64", background: {alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(64px, 100vw)", ...toResponsiveImage(EhBSjETo1)}}, ibPj7DX6I: {"data-framer-name": "XS - 24x24", background: {alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(24px, 100vw)", ...toResponsiveImage(EhBSjETo1)}}, iOAlCaNzr: {"data-framer-name": "S - 32x32", background: {alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(32px, 100vw)", ...toResponsiveImage(EhBSjETo1)}}, szOBHJWto: {"data-framer-name": "2XS - 16x16", background: {alt: "", fit: "fill", intrinsicHeight: 512, intrinsicWidth: 512, pixelHeight: 512, pixelWidth: 512, sizes: "min(16px, 100vw)", ...toResponsiveImage(EhBSjETo1)}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-Y9Ho0[data-border=\"true\"]::after, .framer-Y9Ho0 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Y9Ho0.framer-ookb7n, .framer-Y9Ho0 .framer-ookb7n { display: block; }", ".framer-Y9Ho0.framer-1n00dmi { height: 80px; overflow: hidden; position: relative; width: 80px; }", ".framer-Y9Ho0.framer-v-ks2ohm.framer-1n00dmi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 64px); width: 64px; }", ".framer-Y9Ho0.framer-v-1wgnxed.framer-1n00dmi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 40px); width: 40px; }", ".framer-Y9Ho0.framer-v-1or0zkj.framer-1n00dmi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); width: 32px; }", ".framer-Y9Ho0.framer-v-oca03e.framer-1n00dmi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 24px); width: 24px; }", ".framer-Y9Ho0.framer-v-7qsfcq.framer-1n00dmi { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 80
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"gzckTe59u":{"layout":["fixed","fixed"]},"GSiUx9rZ2":{"layout":["fixed","fixed"]},"iOAlCaNzr":{"layout":["fixed","fixed"]},"ibPj7DX6I":{"layout":["fixed","fixed"]},"szOBHJWto":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"EhBSjETo1":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramertVz5wVyQ8: React.ComponentType<Props> = withCSS(Component, css, "framer-Y9Ho0") as typeof Component;
export default FramertVz5wVyQ8;

FramertVz5wVyQ8.displayName = "Icon";

FramertVz5wVyQ8.defaultProps = {height: 80, width: 80};

addPropertyControls(FramertVz5wVyQ8, {variant: {options: ["SbIrlWYIp", "gzckTe59u", "GSiUx9rZ2", "iOAlCaNzr", "ibPj7DX6I", "szOBHJWto"], optionTitles: ["XL - 80x80", "L - 64x64", "M - 40x40", "S - 32x32", "XS - 24x24", "2XS - 16x16"], title: "Variant", type: ControlType.Enum}, EhBSjETo1: {__defaultAssetReference: "data:framer/asset-reference,LDZoIENyXzBGgbFwxpOPuM1WFdY.png?originalFilename=image-square.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramertVz5wVyQ8, [])